//axios配置
import axios from 'axios'
import {Message} from 'element-ui'
import store from '../vuex/index'
const token = window.localStorage.getItem("token")
import router from "../router/index";
const instance = axios.create({
    //baseURL: 'http://43.143.154.51:8900/api',
    // baseURL: 'http://127.0.0.1:8900/',
    // baseURL:'/api',
    timeout: 100000,
})
const cancelToken = axios.CancelToken;
const source = cancelToken.source();
if (process.env.NODE_ENV === 'production') {
    instance.defaults.baseURL="/api"
}else {
    instance.defaults.baseURL='http://127.0.0.1:8900/'
    // instance.defaults.baseURL='https://anxiaoni.com/api/'
    // instance.defaults.baseURL='http://[fe80::c9ad:a718:a7fb:5112%4]:8900/'
}
// instance.defaults.headers.common["Authorization"] = token
instance.interceptors.request.use((config)=>{
    config.headers.Authorization= window.localStorage.getItem("token")
    return config
},error => {
    return new Promise.reject(error)
})
instance.interceptors.response.use((response)=>{
    let res = response.data
    if (res.code=='403'){
        source.cancel('logout');
        //登录信息失效
        window.localStorage.setItem("token",'')
        store.commit("clearObj")
        Message({
            message: "登录信息失效，请重新登录",
            type: 'error',
            duration:3000,
        })
        router.replace({path:'/Login'})
        return Promise.reject(new Error(res.message))
    }
    return res
},error => {
    console.log(error)
    return new Promise.reject(error)
})
export default instance
