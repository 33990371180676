<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Login from "@/components/Login";
import {getAction} from "@/util/request";

export default {
  name: 'App',
  components: {
    Login,
    HelloWorld
  },
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      window.onload = () => {
        this.loading = false;
        localStorage.setItem('height', window.innerHeight);
      };
    })
  },
  async created() {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
      sessionStorage.removeItem('store')
      // console.log(JSON.parse(sessionStorage.getItem('store')))  // 打印
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      // console.log(JSON.parse(sessionStorage.getItem('store')))  // 打印
    })
    const token = window.localStorage.getItem("token")
    if (token == null || token.length == 0) {
      //无token
      this.$router.replace("/Login")
    } else {
      if (this.$store.state.obj.value == false) {
        //TODO 先验证token信息
        //有token ,请求角色信息
        try {
          const res = await getAction("/people/getInfoByToken")
          if (res.success) {
            this.$store.commit("updateObj", res.result)
            if (!location.hash.includes('/OrderRecord')) {
              this.$router.replace("/Index")
            }
          }
        } catch (e) {
          this.$message.error("获取角色信息异常,请重新登录")
          window.localStorage.setItem("token", "")
          this.$router.replace("/Login")
        }
      }
    }
  },
  destroyed() {
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border: 0;
}

#nprogress .bar {
  background: #58a0ff !important;
}


.line-btn-group {
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}

/* 设置滚动条的宽度 */
::-webkit-scrollbar {
  width: 8px;
}

/* 设置滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 5px !important;
}

/* 设置滚动条在鼠标悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #939393 !important;
}

</style>
